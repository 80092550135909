import Link from 'next/link';
import type { FunctionComponent } from 'react';
import { Box } from '../box';
import { Skeleton } from '../skeleton';
import styles from './breadcrumbs.module.scss';
export const Breadcrumbs: FunctionComponent<React.PropsWithChildren<Props>> = ({
  links = [],
  current,
  loading,
  ...restOfProps
}) => <Box pad={[]} data-sentry-element="Box" data-sentry-component="Breadcrumbs" data-sentry-source-file="index.tsx">
        <ol itemScope className={styles.breadcrumbs} itemType='https://schema.org/BreadcrumbList' {...restOfProps}>
            {loading && !links.length ? <li className={styles.item}>
                    <Skeleton className={styles.skeleton} width='100px' />
                </li> : links.filter(l => l.text).map(({
      text,
      href,
      as
    }, index) => <li itemScope key={href} itemProp='itemListElement' itemType='https://schema.org/ListItem' className={styles.item}>
                            <Link as={as} href={href} className={styles.link} itemProp='item'>
                                <span itemProp='name'>{text}</span>
                            </Link>
                            <meta itemProp='position' content={`${index + 1}`} />
                        </li>)}

            <li className={styles.item}>
                {loading ? <Skeleton className={styles.skeleton} width='200px' /> : <span className={styles.current}>{current}</span>}
            </li>
        </ol>
    </Box>;
export interface BreadcrumbType {
  text: string;
  href: string;
  as?: string;
}
interface Props extends Omit<JSX.IntrinsicElements['ol'], 'className'> {
  links: BreadcrumbType[];
  current: string;
  loading?: boolean;
}
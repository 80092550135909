import classNames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { TEXT } from '../../lib/utils/text';
import type { AuthorProps } from '../article-byline';
import { ArticleByline } from '../article-byline';
import type { ArticleCoverProps } from '../article-cover';
import { ArticleCover } from '../article-cover';
import { Paragraph } from '../paragraph';
import { ShareButton } from '../share-button';
import { Skeleton } from '../skeleton';
import { Text } from '../text';
import styles from './article.module.scss';

/**
 * Displays an article with markdown support for subtitle and body.
 */
export const Article: FunctionComponent<Props> = ({
  title = '',
  loading,
  subtitle = '',
  children,
  dateTime,
  cover,
  author,
  showShareButton = false,
  ...restOfProps
}) => <article className={styles.article} {...restOfProps} data-sentry-component="Article" data-sentry-source-file="index.tsx">
        <header>
            <div className={styles.heading}>
                <Text tag='h1' typeStyle={{
        _: 'heading-sm',
        md: 'heading-2xl'
      }} data-sentry-element="Text" data-sentry-source-file="index.tsx">
                    {loading ? '' : title}
                </Text>
            </div>
            {loading ? <SkeletonSection /> : subtitle}
            <div className={styles['byline-row']}>
                <div className={styles['byline-col']}>
                    <ArticleByline author={author} dateTime={dateTime} loading={loading} data-sentry-element="ArticleByline" data-sentry-source-file="index.tsx" />
                </div>
                {showShareButton && <div className={classNames(styles['byline-col'], styles['share-col'])}>
                        <ShareButton title={title} text={title} placement='header' />
                    </div>}
            </div>
        </header>
        {(cover || loading) && <ArticleCover loading={loading} {...cover} />}
        {loading ? <>
                <SkeletonSection />
                <SkeletonSection />
                <SkeletonSection />
            </> : children}
        {showShareButton && <div className={styles['footer-share-row']}>
                <ShareButton title={title} text={title} label={TEXT.share} placement='footer' />
            </div>}
    </article>;
const SkeletonSection = () => <Paragraph data-sentry-element="Paragraph" data-sentry-component="SkeletonSection" data-sentry-source-file="index.tsx">
        <Skeleton className={styles.skeleton} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
        <Skeleton className={styles.skeleton} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
        <Skeleton className={styles.skeleton} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
    </Paragraph>;
type Props = {
  /**
   * Publish date.
   */
  dateTime?: Date;

  /**
   * Author of the article.
   */
  author?: AuthorProps;

  /**
   * Image/video displayed on top of the article. Overrided by coverVideo.
   */
  cover?: ArticleCoverProps;

  /**
   * Content of the article.
   */
  children?: ReactNode;

  /**
   * Article subtitle.
   */
  subtitle?: ReactNode;

  /**
   * Plain text
   */
  title?: string;

  /**
   * Displays a loading state.
   */
  loading?: boolean;

  /**
   * Displays a share button
   */
  showShareButton?: boolean;
};
import { asPossessive } from '@getstep/sdk/dist/util/Names';
import classNames from 'classnames';
import upperFirst from 'lodash/upperFirst';
import type { FunctionComponent } from 'react';
import { initialsFromName } from '../../lib/utils/name';
import type { WithTestId } from '../../lib/utils/testid';
import { TEXT } from '../../lib/utils/text';
import { avatarSizeLarge, avatarSizeMedium, avatarSizeSmall, avatarSizeXlarge } from '../../styles/variables.module.scss';
import { Badge } from '../badge';
import { Image } from '../image';
import styles from './avatar.module.scss';

/**
 * Displays an avatar image, or initials with background color.
 */
export const Avatar: FunctionComponent<React.PropsWithChildren<Props>> = ({
  source,
  name,
  size,
  badge,
  border = true,
  ...restOfProps
}) => {
  const width = AVATAR_SIZES[size];
  return <figure title={`${asPossessive(name ?? upperFirst(TEXT.someone))} avatar`} className={classNames(styles.container, styles[size])} {...restOfProps} data-sentry-component="Avatar" data-sentry-source-file="index.tsx">
            <div className={classNames(styles.avatar, styles[size], {
      [styles.placeholder]: !name,
      [styles.border]: border,
      [styles['has-image']]: !!source
    })}>
                {source && <Image source={source} alt={name ?? 'avatar'} width={width} fit='cover' className={classNames(styles.image)} />}
                <figcaption className={styles.initials} data-sentry-element="figcaption" data-sentry-source-file="index.tsx">
                    {initialsFromName(name)}
                </figcaption>
            </div>

            {badge && <div className={styles.badge}>
                    <Badge size={avatarSizeToBadgeSize[size]}>{badge}</Badge>
                </div>}
        </figure>;
};
const avatarSizeToBadgeSize = {
  xlarge: 'medium'
} as const;
export const AVATAR_SIZES = {
  large: avatarSizeLarge,
  medium: avatarSizeMedium,
  small: avatarSizeSmall,
  xlarge: avatarSizeXlarge
};
type Props = AvatarProps;
export interface AvatarProps extends WithTestId {
  /**
   * The source of the image. If not provided, initials will be displayed.
   */
  source?: string;

  /**
   * The size of the avatar, equivalent to its diameter.
   * In case there's a border, this is the diameter of the inner circle of the avatar.
   */
  size: 'medium' | 'large' | 'xlarge';

  /**
   * Name of the person that owns the avatar.
   * This is used to generate the initials on the avatar, if there is no image.
   * If there is an image, the name is only used for accessibility purposes.
   * If not provided, `INITIALS_PLACEHOLDER` will be displayed.
   */
  name?: string;

  /**
   * Badge to display, sticks to the bottom of the avatar.
   */
  badge?: string;

  /**
   * Whether the avatar has a border.
   * Defaults to `true`.
   */
  border?: boolean;
}
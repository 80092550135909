import { initials, splitNames } from '@getstep/sdk/dist/util/Names'

export const INITIALS_PLACEHOLDER = '?'
/**
 * Converts a user's name to avatar-compatible initials.
 */
export const initialsFromName = (name?: string) => {
    if (!name) return INITIALS_PLACEHOLDER
    const { firstName, lastName } = splitNames(name)
    return initials(firstName, lastName)
}

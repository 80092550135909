import classNames from 'classnames';
import { formatISO } from 'date-fns';
import type { FunctionComponent } from 'react';
import { formatDate, isValidDate } from '../../lib/utils/date';
import type { ImageType } from '../../types/image';
import { Avatar } from '../avatar';
import { Skeleton } from '../skeleton';
import styles from './article-byline.module.scss';

/**
 * Displays article author and date.
 */
export const ArticleByline: FunctionComponent<Props> = ({
  loading,
  author,
  dateTime
}) => {
  const timestamp = isValidDate(dateTime) && <time dateTime={formatISO(dateTime)}>
            {formatDate(dateTime, 'MMMM d, yyyy')}
        </time>;
  const {
    avatar,
    name
  } = author ?? {};
  if (avatar || loading) {
    return <div className={styles.byline}>
                <div className={styles.avatar}>
                    <Avatar size='medium' source={avatar?.source} name={name} />
                </div>

                {loading ? <div>
                        <Skeleton className={styles.skeleton} width='300px' />
                        <Skeleton className={styles.skeleton} width='200px' />
                    </div> : <div>
                        <div className={styles.name}>{name}</div>
                        <div>{timestamp}</div>
                    </div>}
            </div>;
  }
  return <div className={classNames(styles.author, styles.name)} data-sentry-component="ArticleByline" data-sentry-source-file="index.tsx">
            {author && <>{author.name}, </>}
            {timestamp}
        </div>;
};
export type AuthorProps = {
  avatar?: ImageType;
  name?: string;
};
type Props = {
  loading?: boolean;
  author?: AuthorProps;
  dateTime?: Date;
};